import { api } from '@/api/clients'
import { createStore } from '@/helpers'

type AuthState = {
  accessToken: string
  refreshToken: string
}

const [authStore, useAuthState] = createStore(
  'auth',
  {
    accessToken: '',
    refreshToken: '',
  } as AuthState,
  state => ({
    get isAuthorized() {
      return Boolean(state.accessToken)
    },
  }),
  state => ({
    // TODO: implement auth
    async signIn(addr: string) {
      // const data = await authorizeAdmin(password)

      state.accessToken = addr

      // this.addAuthInterceptor()
    },
    signOut() {
      state.accessToken = ''
      state.refreshToken = ''
      api.clearInterceptors()
    },
    // TODO: implement auth
    verifyToken() {
      // const parsedJwt = parseJwt(state.accessToken)
      // if (!parsedJwt || parsedJwt.exp < Date.now() / 1000) {
      //   this.signOut()
      // }
      // this.addAuthInterceptor()
    },
    addAuthInterceptor() {
      api.addInterceptor({
        request: async config => {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${state.accessToken}`,
          }
          return config
        },
        response: async response => {
          if (response.status === 401) {
            this.signOut()
          }
          return response
        },
      })
    },
  }),
  { persistProperties: ['accessToken'] },
)

export { authStore, useAuthState }
