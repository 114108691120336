import en from './resources/en.json'
import ka from './resources/ka.json'

export default {
  en: {
    translation: {
      ...en,
    },
  },
  ka: {
    translation: {
      ...ka,
    },
  },
}
