import { providers } from 'ethers'
import { useCallback, useMemo } from 'react'

import { config } from '@/config'
import { useWeb3State } from '@/store'
import { ConditionalToken__factory } from '@/types/contracts'

export const useConditionalToken = (address = config.CONDITIONAL_TOKEN_CONTRACT) => {
  const { provider, address: userAddr } = useWeb3State()

  const contractInterface = useMemo(() => ConditionalToken__factory.createInterface(), [])

  const contractInstance = useMemo(() => {
    if (!address || !provider.rawProvider) return null
    return ConditionalToken__factory.connect(
      address,
      new providers.Web3Provider(provider.rawProvider as providers.ExternalProvider),
    )
  }, [provider, address])

  const setApprovalForAll = useCallback(() => {
    if (!contractInstance || !address) throw new ReferenceError('Contract issue')

    const data = contractInterface.encodeFunctionData('setApprovalForAll', [
      config.ADAPTER_CONTRACT,
      true,
    ])

    const txBody = {
      to: address,
      data,
    }
    return provider.signAndSendTx?.(txBody)
  }, [contractInstance, address, provider, contractInterface])

  const getIsApprovedForAll = useCallback(() => {
    if (!contractInstance || !userAddr) throw new ReferenceError('Contract is missing')

    return contractInstance.isApprovedForAll(userAddr, config.ADAPTER_CONTRACT)
  }, [contractInstance, userAddr])

  return {
    setApprovalForAll,
    getIsApprovedForAll,
  }
}
