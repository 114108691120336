import { constants, utils } from 'ethers'
import { useCallback, useEffect, useState } from 'react'

import { useWeb3State } from '@/store'
import { OrderSide } from '@/types'
import { OrderStruct } from '@/types/contracts/NegRiskCtfExchange'

const MAX_TIMESTAMP_VALUE = (Math.pow(2, 32) - 1).toString()

export const useOrder = (): {
  order: Partial<OrderStruct>
  createOrder: ({
    tokenId,
    makerAmount,
    takerAmount,
    side,
  }: {
    tokenId: string
    makerAmount: string
    takerAmount: string
    side: OrderSide
  }) => Partial<OrderStruct>
} => {
  const [order, setOrder] = useState<Partial<OrderStruct>>({
    taker: constants.AddressZero,
    expiration: MAX_TIMESTAMP_VALUE,
    feeRateBps: '0',
    signatureType: 0,
  })
  const { isConnected, address } = useWeb3State()

  const createOrder = useCallback(
    ({
      tokenId,
      makerAmount,
      takerAmount,
      side,
    }: {
      tokenId: string
      makerAmount: string
      takerAmount: string
      side: OrderSide
    }) => {
      // TODO: validate fields
      return {
        ...order,
        salt: utils.hexlify(utils.randomBytes(32)),
        tokenId,
        makerAmount,
        takerAmount,
        side,
      }
    },
    [order],
  )

  useEffect(() => {
    const isSameAddress = order.maker === address && order.signer === address
    if (!isConnected || !address || isSameAddress) return

    setOrder({
      ...order,
      maker: address,
      signer: address,
    })
  }, [isConnected, address, order])

  return { order, createOrder }
}
