import { Switch, SwitchProps } from '@mui/material'

export default function LanguageSwitch({ ...props }: SwitchProps) {
  return (
    <Switch
      {...props}
      sx={theme => ({
        width: 100,
        height: 32,
        '&:has(.Mui-checked)::after': {
          color: theme.palette.text.primary,
        },
        '&:not(:has(.Mui-checked))::before': {
          color: theme.palette.text.primary,
        },
        '& .MuiSwitch-thumb': {
          width: 46,
          height: 28,
          borderRadius: 1000,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(50px)',
        },
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: '50%',
          transform: 'translateY(-50%)',
          width: 46,
          height: 28,
          zIndex: 10000,
          borderRadius: 1000,
          color: theme.palette.text.secondary,
          transition: theme.transitions.create('color'),
        },
        '&:before': {
          content: '"ENG"',
          left: 0,
          ...theme.typography.overline3,
        },
        '&:after': {
          content: '"GEO"',
          right: 0,
          ...theme.typography.overline3,
        },
      })}
    />
  )
}
