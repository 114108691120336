import { BN } from '@distributedlab/tools'
import { constants, providers } from 'ethers'
import { useCallback, useEffect, useMemo } from 'react'

import { config } from '@/config'
import { ErrorHandler } from '@/helpers'
import { useWeb3State, web3Store } from '@/store'
import { CollateralToken__factory } from '@/types/contracts'

export const useCollateralToken = (address = config.COLLATERAL_TOKEN_CONTRACT) => {
  const { provider, address: userAddr } = useWeb3State()

  const contractInterface = useMemo(() => CollateralToken__factory.createInterface(), [])

  const contractInstance = useMemo(() => {
    if (!address || !provider.rawProvider) return null
    return CollateralToken__factory.connect(
      address,
      new providers.Web3Provider(provider.rawProvider as providers.ExternalProvider),
    )
  }, [provider, address])

  useEffect(() => {
    const address = provider.address
    if (!contractInstance || !address) return
    const loadBalance = async () => {
      try {
        const balance = await contractInstance.balanceOf(address)
        web3Store.setBalance(balance.toString())
      } catch (e) {
        ErrorHandler.processWithoutFeedback(e)
      }
    }
    loadBalance()

    const handleBalanceChange = (from: string, to: string) => {
      if (from === address || to === address) {
        loadBalance()
      }
    }

    contractInstance.on('Transfer', handleBalanceChange)
    return () => {
      contractInstance.off('Transfer', handleBalanceChange)
    }
  }, [contractInstance, provider.address])

  const approve = useCallback(() => {
    if (!contractInstance || !address) throw new ReferenceError('Contract issue')

    const data = contractInterface.encodeFunctionData('approve', [
      config.ADAPTER_CONTRACT,
      constants.MaxUint256,
    ])

    const txBody = {
      to: address,
      data,
    }
    return provider.signAndSendTx?.(txBody)
  }, [contractInstance, address, provider, contractInterface])

  const getIsApproved = useCallback(async () => {
    if (!contractInstance || !userAddr) throw new ReferenceError('Contract is missing')

    const allowance = await contractInstance.allowance(userAddr, config.ADAPTER_CONTRACT)
    return allowance.eq(constants.MaxUint256)
  }, [contractInstance, userAddr])

  const mint = useCallback(
    async (amount: string) => {
      if (!contractInstance || !userAddr) throw new TypeError('Invalid address or provider')

      const data = contractInterface.encodeFunctionData('mint', [
        userAddr,
        BN.fromRaw(amount).value,
      ])

      const txBody = {
        to: address,
        data,
      }

      return provider?.signAndSendTx?.(txBody)
    },
    [contractInstance, address, provider, contractInterface, userAddr],
  )

  return {
    approve,
    getIsApproved,
    mint,
  }
}
