import {
  type ChainId,
  CoinbaseProvider,
  createProvider,
  errors,
  MetamaskProvider,
  Provider,
  ProviderDetector,
  ProviderProxyConstructor,
  PROVIDERS,
} from '@distributedlab/w3p'
import { providers, Signer } from 'ethers'
import { ref } from 'valtio'

import {
  chainIdToNetworkMap,
  connectorParametersMap,
  NETWORK_NAME,
  networkConfigsMap,
} from '@/constants/network-config'
import { createStore, sleep } from '@/helpers'

// export enum FALLBACK_PROVIDER_NAMES {
//   mainnetFallback = 'mainnetfallback',
//   testnetFallback = 'testnetfallback',
// }

// const FALLBACK_PROVIDERS_TYPES = {
//   mainnet: FALLBACK_PROVIDER_NAMES.mainnetFallback,
//   testnet: FALLBACK_PROVIDER_NAMES.testnetFallback,
// }

// const ORIGIN_PROVIDER_TYPE = FALLBACK_PROVIDERS_TYPES[NETWORK_NAME]

export type SupportedProviders = PROVIDERS //| FALLBACK_PROVIDER_NAMES

type Web3Store = {
  provider: Provider
  providerDetector: ProviderDetector<PROVIDERS> | undefined
  providerType: PROVIDERS | undefined
  providerChainId: ChainId | undefined
  gsnSigner: Signer | undefined
  balance: string
}

const providerDetector = new ProviderDetector<PROVIDERS>()

const PROVIDERS_PROXIES: { [key in PROVIDERS]?: ProviderProxyConstructor } = {
  [PROVIDERS.Metamask]: MetamaskProvider,
  [PROVIDERS.Coinbase]: CoinbaseProvider,
}

Provider.setChainsDetails(connectorParametersMap)

export const [web3Store, useWeb3State] = createStore(
  'web3',
  {
    provider: {} as Provider,
    providerDetector: undefined,
    providerType: undefined,
    providerChainId: undefined,
    balance: '0',
  } as Web3Store,
  state => ({
    get isRightNetwork(): boolean {
      return Boolean(state.provider?.chainId && chainIdToNetworkMap[state.provider.chainId])
    },

    get isConnected(): boolean {
      return Boolean(state.provider?.isConnected)
    },

    get address(): string | undefined {
      return state.provider?.address
    },

    get injectedProvider() {
      if (!state.provider.rawProvider) return undefined

      return new providers.Web3Provider(
        state.provider.rawProvider as providers.ExternalProvider,
        'any',
      )
    },
  }),
  state => ({
    init: async (_providerType?: PROVIDERS) => {
      let providerType = _providerType

      if (!providerType) {
        providerType = state.providerType
      }

      if (!providerType || !(providerType in PROVIDERS_PROXIES))
        throw new TypeError('Provider not supported')

      const providerProxy = PROVIDERS_PROXIES[providerType]!

      state.provider?.clearHandlers?.()

      state.providerDetector = ref(providerDetector)

      /**
       * because of proxy aint works with private fields in objects, we should use `valtio ref`,
       * and to keep valtio proxy "rolling" - we should update state ref property,
       * e.g. onAccountChanged or onChainChanged, ...etc
       */
      const initiatedProvider = await createProvider(providerProxy, {
        providerDetector,
        listeners: {
          onChainChanged: e => {
            state.providerChainId = e?.chainId
            web3Store.init(providerType)
          },
          onAccountChanged: e => {
            // HOTFIX: double check if user disconnected from wallet
            if (!e?.address) {
              web3Store.disconnect()

              return
            }

            web3Store.init(providerType)
          },
          onDisconnect: () => {
            web3Store.disconnect()
          },
        },
      })

      // TODO: fix global rerenders

      state.provider = ref(initiatedProvider)

      state.providerType = providerType
      state.providerChainId = initiatedProvider.chainId

      await state.provider.connect()

      // hotfix injected provider listeners updating provider proxy object
      await sleep(300)
    },

    setBalance(balance: string) {
      state.balance = balance
    },

    safeSwitchNetwork: async (chainId: ChainId) => {
      if (!state.provider?.address) {
        return
      }

      try {
        await web3Store.provider?.switchChain(chainId)
      } catch (error) {
        if (
          error instanceof errors.ProviderInternalError ||
          error instanceof errors.ProviderChainNotFoundError
        ) {
          const chainDetails = Provider.chainsDetails?.[chainId]

          if (!chainDetails) {
            throw error
          }

          await web3Store.provider?.addChain(chainDetails)

          return
        }

        throw error
      }
    },

    disconnect: async () => {
      await state.provider?.disconnect()

      state.providerType = undefined
      state.provider = {} as Provider
    },

    getNetworkConfig: () => {
      const networkName = state.provider?.chainId && chainIdToNetworkMap?.[state.provider?.chainId]

      return networkConfigsMap[networkName || NETWORK_NAME]
    },
  }),
  {
    persistProperties: ['providerType', 'providerChainId'],
  },
)
