import { Chain, CHAIN_TYPES } from '@distributedlab/w3p'
import { utils } from 'ethers'

import { config } from '@/config'

export type NetworkName = 'mainnet' | 'testnet'

export interface NetworkConfig {
  chainId: number
  name: string
  networkName: NetworkName
  rpcUrl: string
  explorerUrl: string
}

export const networkConfigsMap: Record<NetworkName, NetworkConfig> = {
  mainnet: {
    chainId: 1,
    name: 'Mainnet',
    networkName: 'mainnet',
    rpcUrl: 'https://mainnet.infura.io/v3', // doesn't work w/o a key
    explorerUrl: 'https://etherscan.io',
    // contract: '0x1231231',
  },
  testnet: {
    chainId: 11155111,
    name: 'Sepolia',
    networkName: 'testnet',
    rpcUrl: 'https://ethereum-sepolia.rpc.subquery.network/public',
    explorerUrl: 'https://explorer.qtestnet.org/',
    // contract: '0x1231231',
  },
}

export const chainIdToNetworkMap: { [key: string]: NetworkName } = {
  1: 'mainnet',
  11155111: 'testnet',
}

export const connectorParametersMap = Object.values(networkConfigsMap).reduce(
  (acc, config) => {
    const token =
      config.chainId === 1
        ? {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18,
          }
        : {
            name: 'Polygon',
            symbol: 'MATIC',
            decimals: 18,
          }
    acc[config.chainId] = {
      id: utils.hexlify(config.chainId),
      name: config.name,
      rpcUrl: config.rpcUrl,
      explorerUrl: config.explorerUrl,
      type: CHAIN_TYPES.EVM,
      token,
      icon: '',
    }
    return acc
  },
  {} as { [key: string]: Chain },
)

export const NETWORK_NAME = (config.NETWORK || 'testnet') as NetworkName
