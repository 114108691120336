import packageJson from '../package.json'

export type Config = {
  APP_NAME: string
  // APP_HOST_URL: string
  API_URL: string
  BUILD_VERSION: string
  // TERMS_LINK: string
  // PRIVACY_NOTICE_LINK: string
  APP_STORE_APP_LINK: string
  GOOGLE_PLAY_APP_LINK: string
  NETWORK: string
  COLLATERAL_TOKEN_CONTRACT: string
  CONDITIONAL_TOKEN_CONTRACT: string
  ADAPTER_CONTRACT: string
  CTF_EXCHANGE_CONTRACT: string
}

export const config: Config = {
  APP_NAME: import.meta.env.VITE_APP_NAME,
  // APP_HOST_URL: import.meta.env.VITE_APP_HOST_URL,
  API_URL: import.meta.env.VITE_API_URL,
  BUILD_VERSION: packageJson.version || import.meta.env.VITE_APP_BUILD_VERSION,
  // TERMS_LINK: '/general-terms.html',
  // PRIVACY_NOTICE_LINK: '/privacy-notice.html',
  APP_STORE_APP_LINK: 'https://google.com',
  GOOGLE_PLAY_APP_LINK: 'https://google.com',
  NETWORK: import.meta.env.VITE_NETWORK,
  COLLATERAL_TOKEN_CONTRACT: import.meta.env.VITE_COLLATERAL_TOKEN_CONTRACT,
  CONDITIONAL_TOKEN_CONTRACT: import.meta.env.VITE_CONDITIONAL_TOKEN_CONTRACT,
  ADAPTER_CONTRACT: import.meta.env.VITE_ADAPTER_CONTRACT,
  CTF_EXCHANGE_CONTRACT: import.meta.env.VITE_CTF_EXCHANGE_CONTRACT,
}
