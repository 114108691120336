import { PROVIDERS } from '@distributedlab/w3p'
import {
  Button,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { AppMenu, ProfileMenu } from '@/common'
import LanguageSwitch from '@/common/LanguageSwitch'
import Web3DetailsModal from '@/common/Web3DetailsModal'
import { Icons, RoutePaths } from '@/enums'
import { formatAmount } from '@/helpers'
import { ENGLISH_LOCALE, GEORGIAN_LOCALE } from '@/locales'
import { uiStore, useWeb3State, web3Store } from '@/store'
import { UiIcon } from '@/ui'

const menuItemSx: SxProps = { p: 2, justifyContent: 'space-between' }

const AppNavbar = () => {
  const { palette } = useTheme()
  const { isConnected, balance } = useWeb3State()
  const [isWeb3DetailsOpen, setIsWeb3DetailsOpen] = useState(false)

  return (
    <Stack py={5} borderBottom={1} borderColor={palette.action.active}>
      <Stack
        maxWidth={1176}
        mx='auto'
        width='100%'
        px={4}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Stack
          component={NavLink}
          to={RoutePaths.Root}
          alignItems='center'
          direction='row'
          spacing={3}
          sx={theme => ({
            color: theme.palette.text.primary,
            ...theme.typography.h5,
          })}
        >
          <UiIcon name={Icons.App} size={8} sx={{ color: palette.text.primary }} />
          t2c.com
        </Stack>

        <Stack spacing={4} direction='row'>
          {isConnected ? (
            <>
              <Tooltip title={formatAmount(balance, { decimals: 18 })}>
                <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  sx={theme => ({
                    color: theme.palette.primary.dark,
                    backgroundColor: theme.palette.primary.lighter,
                    borderRadius: 1000,
                    p: theme.spacing(2.5, 6),
                  })}
                >
                  <UiIcon name={Icons.Points} size={5} />
                  <Typography variant='buttonMedium'>{formatAmount(balance)}</Typography>
                </Stack>
              </Tooltip>
              <ProfileMenu />
            </>
          ) : (
            <ConnectWalletMenu />
          )}
          <SettingsMenu />
          {isConnected && (
            <Button size='medium' onClick={() => setIsWeb3DetailsOpen(true)}>
              Open Approves Modal
            </Button>
          )}
          <Web3DetailsModal open={isWeb3DetailsOpen} onClose={() => setIsWeb3DetailsOpen(false)} />
        </Stack>
      </Stack>
    </Stack>
  )
}

const SettingsMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { t, i18n } = useTranslation()
  const { paletteMode, setPaletteMode } = uiStore

  const isDarkMode = paletteMode === 'dark'

  const togglePaletteMode = useCallback(() => {
    setPaletteMode(isDarkMode ? 'light' : 'dark')
  }, [setPaletteMode, isDarkMode])

  const toggleLanguages = useCallback(() => {
    i18n.changeLanguage(i18n.language === ENGLISH_LOCALE ? GEORGIAN_LOCALE : ENGLISH_LOCALE)
  }, [i18n])

  return (
    <>
      <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
        <UiIcon name={Icons.List} size={5} />
      </IconButton>
      <AppMenu anchorEl={anchorEl} id='settings-menu' onClose={() => setAnchorEl(null)}>
        <MenuItem sx={menuItemSx} onClick={() => toggleLanguages()}>
          <Typography variant='buttonMedium'>{t('app-navbar.language-switch-lbl')}</Typography>
          <LanguageSwitch
            checked={i18n.language === ENGLISH_LOCALE}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </MenuItem>

        <MenuItem sx={menuItemSx} onClick={togglePaletteMode}>
          <Typography variant='buttonMedium'>{t('app-navbar.theme-switch-dark-lbl')}</Typography>
          <Switch checked={isDarkMode} inputProps={{ 'aria-label': 'controlled' }} />
        </MenuItem>
      </AppMenu>
    </>
  )
}

const ConnectWalletMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { t } = useTranslation()

  const menuItemSxOverride = {
    ...menuItemSx,
    width: 264,
    justifyContent: 'flex-start',
    gap: 4.5,
  }

  return (
    <>
      <Button size='medium' onClick={event => setAnchorEl(event.currentTarget)} variant='contained'>
        {t('app-navbar.connect-button-lbl')}
      </Button>
      <AppMenu
        anchorEl={anchorEl}
        id='connect-wallet-menu'
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiMenu-list': {
            width: 'auto',
          },
        }}
      >
        <MenuItem sx={menuItemSxOverride} onClick={() => {}}>
          <UiIcon name={Icons.UnitedSpaceApp} size={5} />
          <Typography variant='buttonMedium'>{t('app-navbar.united-space-app-lbl')}</Typography>
          <UiIcon
            name={Icons.ArrowRight}
            size={5}
            ml='auto'
            color={({ palette }) => palette.text.secondary}
          />
        </MenuItem>

        <MenuItem sx={menuItemSxOverride} onClick={() => web3Store.init(PROVIDERS.Metamask)}>
          <UiIcon name={Icons.Metamask} size={5} />
          <Typography variant='buttonMedium'>{t('app-navbar.metamask-lbl')}</Typography>
          <UiIcon
            name={Icons.ArrowRight}
            size={5}
            ml='auto'
            color={({ palette }) => palette.text.secondary}
          />
        </MenuItem>
      </AppMenu>
    </>
  )
}

export default AppNavbar
